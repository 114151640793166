import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import Layout from "../components/layout"

const UnRestrictedRoute = ({ component: Component, layoutSettings = {}, ...rest }) => {
  const location = useLocation()
  useEffect(() => {
    document.body.setAttribute('data-page', window.location.pathname.replace(/\//g, ''))
  }, [location]);

  return (
    <Route
      {...rest}
      render={props =>
        <Layout settings={{ ...layoutSettings }}>
          <Component {...props} settings={layoutSettings} />
        </Layout>
      }
    />
  )
}
export { UnRestrictedRoute }