import React, { Component } from 'react'
class Layout extends Component {
    constructor(props) {
      super(props)
    this.state={};
  }

  
      render() {
          const { children } = this.props
          return (
              <div className="">
                  {children}
              </div>
          )
      }
  }
  
  export default Layout