import { init } from '@rematch/core';
import * as models from './models';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

const middlewares = [routeMiddleware];

const store = init({
  redux: {
    middlewares: middlewares,
  },
  models,
});
export { store , history };
