import { Auth } from "aws-amplify";
import queryString from "query-string";
import Cookies from "js-cookie";
import config from "../../config";
import ipapi from "ipapi.co";
import { COOKIES_KEY } from "../constUtil";
import { segmentTrack } from "../../app/helpers/segment";
import {
  getFirstExperience,
  generateApiToken,
} from "../containers/auth/service";

// const cookies = {
//     name: 'gositeAccounts',
//     expiry: 1,
//     domain: ''
// }

const cookies = {
  name: "gosite",
  expiry: 1,
  domain: "",
};

export async function getCognitoUserToken() {
  return await Auth.currentSession()
    .then((res) => res.getIdToken())
    .catch(() => {});
}
export async function getCognitoUserRefreshToken() {
  return await Auth.currentSession()
    .then((res) => res.getRefreshToken())
    .catch(() => {});
}
export const setCookieParentDomain = (name, value) => {
  if (value) {
    Cookies.set(name, value, { domain: config.cookies.parentDomain });
  }
};
export function getQueryParam(paramName, url = window.location.search) {
  const queryParams = queryString.parse(url);
  return queryParams[paramName];
}

export function getAllQueryParam(url = window.location.search) {
  const queryParams = queryString.parse(url);
  return queryParams;
}
export function getAllUniqueQueryParam(url = window.location.search) {
  const queryParams = queryString.parse(url);
  let uniqueQueryParams = {};
  Object.keys(queryParams).forEach((key) => {
    if (Array.isArray(queryParams[key])) {
      uniqueQueryParams[key] = queryParams[key][0];
    } else {
      uniqueQueryParams[key] = queryParams[key];
    }
  });
  return uniqueQueryParams;
}
export function getJsonCookies() {
  const data = Cookies.getJSON(config.cookies.name);
  if (data) {
    return data.token;
  }
}
function cookieName() {
  var uuid = window.location.href.split("/")[3];
  const reg = /^100/;
  if (reg.test(uuid)) {
    return uuid;
  } else {
    const allCookie = Cookies.get();
    for (const key in allCookie) {
      if (reg.test(key)) {
        return key;
      }
    }
  }
}
export function getNonCognitoToken() {
  const data = Cookies.getJSON(cookieName());
  if (data) {
    return data.token;
  }
}
export function setCookiesNonCognito(data, bool) {
  try {
    const cookie = data;
    console.log("data Cookie =============", data);

    let isSMBPartner = false;
    let tt_token = getTokenURL(COOKIES_KEY.THUMBTACK_TOKEN);
    let isMultiLocationPartner = false;
    if (cookie.role === "partner" && cookie.isMultiLocation) {
      if (cookie.total_accounts <= 1) {
        isSMBPartner = true;
        isMultiLocationPartner = false;
      } else {
        isSMBPartner = false;
        isMultiLocationPartner = true;
      }
    } else {
      isSMBPartner = false;
      isMultiLocationPartner = false;
    }

    Cookies.set(
      cookie.user_unique_id,
      {
        token: cookie.token,
        status: cookie.status,
        role: cookie.role,
        user_unique_id: cookie.user_unique_id,
        isMultiLocation: cookie.isMultiLocation,
        active_accounts: cookie.active_accounts,
        total_accounts: cookie.total_accounts,
        isSMBPartner: isSMBPartner,
        isMultiLocationPartner: isMultiLocationPartner,
        businessProfile: cookie.businessProfile,
        site: cookie.site,
        whiteListPermission: cookie.whiteListPermission,
        multilocation: cookie.multilocation,
        customTheme: cookie.customTheme,
        isAwsCognitoUser: cookie.isAwsCognitoUser,
        cogAccessToken: cookie.cogAccessToken,
        isCognito: cookie.isCognito,
        tt_token: tt_token,
      },
      { expires: cookies.expiry, domain: config.cookies.parentDomain },
    );
  } catch (e) {
    console.log(e);
  }
}
export function setCookies(token, bool) {
  try {
    Cookies.set(
      config.cookies.name,
      {
        token: token,
      },
      { expires: config.cookies.expiry, domain: config.cookies.domain },
    );
  } catch (e) {
    console.log(e);
  }
}
export function setCookie(cookieName, setting, expiration = 365) {
  try {
    Cookies.set(cookieName, setting, { expires: expiration });
  } catch (e) {
    console.log(e);
  }
}
export function clearCookies(key) {
  const cName = key || config.cookies.name;
  Cookies.remove(cName, { domain: config.cookies.domain });
}
function userRoleIs(roles, role) {
  return role && roles.indexOf(role) > -1;
}
export function checkPermissionWOproduct(permission, user) {
  if (user && user.permission_names.indexOf(permission) >= 0) {
    return true;
  } else {
    return false;
  }
}
export async function goToCloudDomain(
  me,
  nonCognito = false,
  token = null,
  redirectRoute,
) {
  let isEnterpriseUser =
    config.platformID !== me.platform_id &&
    config.gositeAccountsPlatformId !== me.platform_id;
  let hostname = isEnterpriseUser
    ? me.user_parent_site_url
    : config.cloudDomainUrl;
  await segmentTrack(me);
  // Keeping this to work for QE on the cloud.gosite.cloud environment
  // let hostname = config.cloudDomainUrl;
  if (userRoleIs(["customer", "staff"], me.role_id.role_name)) {
    hostname = `${config.cloudDomainUrl}`;
  }
  console.log("hostname ======", hostname);
  console.log("redirectRoute ======", redirectRoute);
  //TODO:
  //1.upgrade to pro
  //2.Customer,Staff
  //3.trial
  //4.prospect
  //5.'partner','manager'
  if (me && me.isMultiLocation) {
    let route = "";
    hostname = `${config.cloudDomainUrl}`;

    if (me.status === "expired" || me.status === "deleted") {
      route += `/unauthorized`;
    } else {
      const ftuStatus = await getFirstExperience(nonCognito, token);
      const { status = "" } = ftuStatus || {};
      const newFtuSet = new Set([
        "how_can_we_help",
        "create_free_account",
        "secure_your_account",
      ]);
      if (newFtuSet.has(status)) {
        route += `/enrolling`;
      } else if (redirectRoute) {
        route += redirectRoute;
      }
    }

    redirectToDomainMultiLocationUser(hostname, route, me, nonCognito, token);
    // window.open(`${hostname}/${me.user_name}${route}`, "_parent");
  } else {
    if (userRoleIs(["customer", "staff"], me.role_id.role_name)) {
      if (
        me.status === "active" ||
        me.status === "new" ||
        me.status === "inactive"
      ) {
        redirectToDomain({
          hostname,
          route: redirectRoute || "/home",
          me,
          nonCognito,
          isEnterpriseUser,
          token,
        });
      }
    } else if (userRoleIs(["trial"], me.role_id.role_name)) {
      redirectToDomain({
        hostname,
        route: "/trial/home",
        me,
        nonCognito,
        isEnterpriseUser,
        token,
      });
    } else if (userRoleIs(["prospect"], me.role_id.role_name)) {
      console.log("PROSPECT");
    } else if (userRoleIs(["partner", "manager"], me.role_id.role_name)) {
      let platformUrl = me.platform_url || me.user_parent_site_url;
      if (process.env.REACT_APP_ENV === "development") {
        platformUrl = config.enterpriseUrl;
      }
      platformUrl = platformUrl ? platformUrl : config.cloudDomainUrl;
      console.log("platformUrl ======", platformUrl);

      // EVE-2797: for enterprise users with custom domain like https://locations.crewcarwash.com, use legacy token instead of cognito token
      if (me.user_parent_is_custom_domain) {
        const response = await generateApiToken({ user_id: me._id });
        if (response && response.session_id) {
          token = response.session_id;
          nonCognito = true;
        }
      }

      if (checkPermissionWOproduct("Admin.SiteAnalytics.View", me)) {
        redirectToDomain({
          hostname: platformUrl,
          route: "/analytics",
          me,
          nonCognito,
          isEnterpriseUser,
          token,
        });
      } else {
        redirectToDomain({
          hostname: platformUrl,
          route: "/customers",
          me,
          nonCognito,
          isEnterpriseUser,
          token,
        });
      }
    } else {
      redirectToDomain({
        hostname: config.superAdminUrl,
        route: "/customers",
        me,
        nonCognito,
        isEnterpriseUser,
        token,
      });
    }
  }
}

async function redirectToDomainMultiLocationUser(
  url,
  route,
  me,
  nonCognito,
  token = null,
) {
  let redirectUrl = url;
  let qs;
  let tt_token = getTokenURL(COOKIES_KEY.THUMBTACK_TOKEN);
  const params = queryString.parse(window.location.search);
  if (nonCognito) {
    qs = "?t=";
    let accessToken = token;
    if (!accessToken) {
      accessToken = getNonCognitoToken();
    }
    qs +=
      accessToken +
      "&s=" +
      me.status +
      "&r=" +
      me.role_id.role_name +
      "&uuid=" +
      me.user_name;

    if (params["reviewPageId"]) {
      qs += "&reviewPageId=" + params["reviewPageId"];
    }
  } else {
    const cogToken = await getCognitoUserAccessToken();
    qs = "?t=";
    qs +=
      getJsonCookies() +
      "&s=" +
      me.status +
      "&r=" +
      me.role_id.role_name +
      "&uuid=" +
      me.user_name;
    qs += "&isAwsCognitoUser=true";
    qs += `&cogtoken=${cogToken && cogToken.jwtToken}`;
    qs += `&isCognito=true`;

    if (params["reviewPageId"]) {
      qs += "&reviewPageId=" + params["reviewPageId"];
    }
  }

  if (me && me.isMultiLocation) {
    qs +=
      "&isMultiLocation=true&total_accounts=" +
      me.total_accounts +
      "&active_accounts=" +
      me.active_accounts;
  }
  if (tt_token) {
    qs += "&tt_token=" + tt_token;
  }
  redirectUrl += `/${me.user_name}${route}${qs}`;
  console.log("In redirectToDomainMultiLocationUser function");
  console.log("redirectUrl => ", redirectUrl);
  window.open(redirectUrl, "_parent");
}

export function getCookieByKey(key) {
  if (key) {
    return Cookies.get(key);
  }
}

export function getTokenURL(key) {
  if (key) {
    return new URLSearchParams(window.location.search).get(key);
  } else {
    return undefined;
  }
}

export function getURLComponents(url) {
  try {
    return new URL(url);
  } catch (err) {
    console.log("Exception occured", err);
  }
}

async function redirectToDomain({
  hostname,
  route,
  me,
  nonCognito,
  isEnterpriseUser,
  token = null,
}) {
  let redirectUrl = hostname;
  let qs;
  let tt_token = getTokenURL(COOKIES_KEY.THUMBTACK_TOKEN);
  const params = queryString.parse(window.location.search);

  if (nonCognito) {
    qs = "t=";
    let accessToken = token;
    if (!accessToken) {
      accessToken = getNonCognitoToken();
    }
    qs +=
      accessToken +
      "&s=" +
      me.status +
      "&r=" +
      me.role_id.role_name +
      "&uuid=" +
      me.user_name;
    if (tt_token) {
      qs += "&tt_token=" + tt_token;
    }
    if (params["reviewPageId"]) {
      qs += "&reviewPageId=" + params["reviewPageId"];
    }
    redirectUrl += `/${me.user_name}${route}?${qs}`;
  } else {
    const cogToken = await getCognitoUserAccessToken();
    qs = "t=";
    qs +=
      getJsonCookies() +
      "&s=" +
      me.status +
      "&r=" +
      me.role_id.role_name +
      "&uuid=" +
      me.user_name;
    qs += "&isAwsCognitoUser=true";
    qs += `&cogtoken=${cogToken && cogToken.jwtToken}`;
    qs += `&isCognito=true`;
    if (tt_token) {
      qs += "&tt_token=" + tt_token;
    }
    if (params["reviewPageId"]) {
      qs += "&reviewPageId=" + params["reviewPageId"];
    }
    redirectUrl += `/${me.user_name}${route}?${qs}`;
  }
  if (!params["reviewPageId"]) {
    console.log("INSIDE review page if");
    let deepLink = getCookieByKey("deepLink");
    if (deepLink) {
      console.log("INSIDE deepLink if");
      if (isEnterpriseUser) {
        const urlComponents = getURLComponents(deepLink);
        deepLink =
          me.user_parent_site_url +
          urlComponents.pathname +
          urlComponents.search;
      }
      redirectUrl = deepLink + (deepLink.split("?")[1] ? "&" : "?") + qs;
    }
  }
  console.log("redirectUrl===>", redirectUrl);
  console.log("me.user_name===>", me.user_name);
  console.log("route===>", route);
  console.log("qs===>", qs);
  window.open(redirectUrl, "_parent");
}
export function validatePassword(value) {
  if (
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?~!@$ %^&*\-+{}[\]|']).{6,}$/.test(
      value,
    )
  ) {
    return true;
  } else {
    return false;
  }
}
export async function getCognitoUserAccessToken() {
  return await Auth.currentSession()
    .then((res) => res.getAccessToken())
    .catch(() => {});
}
export function deleteAllCookies() {
  Object.keys(Cookies.get()).forEach(function (cookieName) {
    Cookies.remove(cookieName, { path: "", domain: config.cookies.domainName });
  });
}
export function getIP() {
  return new Promise((resolve, reject) => {
    const callback = (res) => {
      if (res && res.ip) {
        resolve(res.ip);
      } else {
        resolve(null);
      }
    };
    ipapi.location(callback);
  });
}

export function setCookiesGoogleNonCognito(googleCookies) {
  setCookieParentDomain(COOKIES_KEY.LOGIN_PROVIDER, COOKIES_KEY.GOOGLE);
  setCookieParentDomain(
    COOKIES_KEY.GOOGLE_REFRESH_TOKEN,
    googleCookies.refreshToken,
  );
  setCookieParentDomain(
    COOKIES_KEY.GOOGLE_ACCESS_TOKEN,
    googleCookies.accessToken,
  );
}

export const getTrimmedEmail = function (email) {
  return String(email).trim().toLowerCase();
};
