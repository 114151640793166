import { api , adminApi, nonCognitoDashboardApi, catchHandler } from "../../helpers/axios";
import config from "../../../config";

export function getMe() {
    return (
        adminApi()
            .get(`${config.routes.me}`)
            // .get(`${config.amazonGatewayUrl}${config.routes.me}`)
            .then((res) => res.data)
            .catch(catchHandler)
    )
}
export function getNonCognitoMe(payload) {
    return (
        nonCognitoDashboardApi(false, payload)
            .get(`${config.routes.me}`)
            // .get(`${config.amazonGatewayUrl}${config.routes.me}`)
            .then((res) => res.data)
            .catch(catchHandler)
    )
}

export function forgetPassword(payload) {
    return (
        api()
            .post(`${config.routes.forgetPassword}`,payload)
            .then((res) => res.data)
            .catch(catchHandler)
    )
}
export function resetPassword(payload) {
    return (
        api()
            .post(`${config.routes.resetPassword}`,payload)
            .then((res) => res.data)
            .catch(catchHandler)
    )
}
export function loginUser(payload) {
    return (
        api()
            .post(`${config.routes.login}`,payload)
            .then((res) => res.data)
            .catch(catchHandler)
    )
}

export function hubspotCreateContact(payload) {
    return (
        api()
            .post(`${config.routes.hubspotCreateContact}`,payload)
            .then((res) => res.data)
            .catch(catchHandler)
    )
}


export function googleAuth (payload) {
    return nonCognitoDashboardApi(true)
      .post(config.routes.googleAuth, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  }
export function getGMBAuthInfo(payload) {
    return nonCognitoDashboardApi(true)
        .post(config.routes.getGMBAuthInfo, payload)
        .then((res) => res.data)
        .catch(catchHandler)
}
export function getGMBInfo(payload) {
    return nonCognitoDashboardApi(true)
        .post(config.routes.getGMBInfo, payload)
        .then((res) => res.data)
        .catch(catchHandler)
}

export function resendConfirmationCode(payload) {
    return (
        api()
            .post(`${config.routes.resendConfirmationCode}`,payload)
            .then((res) => res.data)
            .catch(catchHandler)
    )
}
export function register(payload) {
    console.log("payload ======", payload)
    return (
        api()
            .post(`${config.routes.register}`,payload)
            .then((res) => res.data)
            .catch(catchHandler)
    )
}

export function getFirstExperience(nonCognito, token) {
    if (nonCognito) {
        return nonCognitoDashboardApi(false, token)
            .get(config.routes.firstExperience)
            .then((res) => res.data)
            .catch(catchHandler)
    } else {
        return adminApi()
            .get(config.routes.firstExperience)
            .then((res) => res.data)
            .catch(catchHandler)
    }
}

export function generateApiToken(payload) {
    return (
        adminApi()
            .post(`${config.routes.generateApiToken}`,payload)
            .then((res) => res.data)
            .catch(catchHandler)
    )
}