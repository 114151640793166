import React from 'react'
import { Provider } from "react-redux";
import Amplify from 'aws-amplify';
import config from '../config';
import { store, history } from "./store";
import Routes from "./router";
Amplify.configure(config.AWS_COGNITO_CONFIG);
export default function App() {
    return (
        <Provider store={store}>
            <Routes history={history}/>
        </Provider>
    )
}
