import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import './index.css'
import './app/asset/css/style.css'
import './app/asset/css/bootstrap-grid.css'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import config from './config';
import { GoogleOAuthProvider } from '@react-oauth/google';

ReactDOM.render(
  <React.StrictMode>
    <main className="outer-layout-main-wrapper">
    <ReactNotifications />
    <div className="position-relative d-flex align-items-center auth_page-auth">
      <div className="mx-auto text-center auth_master-form">
        <header className="auth_header">
          <a className="d-inline-block" href="/login">
            <img  src="https://dufzo4epsnvlh.cloudfront.net/image/icon-new/logo-loader.svg" alt="" />
          </a>
        </header>
          <GoogleOAuthProvider clientId={config.google_login_key}>
            <App />
          </GoogleOAuthProvider>
        </div>
    </div>
    </main>
    </React.StrictMode>,
  document.getElementById('root')
);
