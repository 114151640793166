import React, { Component } from "react";

export default function asyncComponent(importComponent) {
    class AsyncFunc extends Component {
        constructor(props) {
            super(props);
            this.state = {
                component: null
            };
        }
        componentWillUnmount() {
            this.mounted = false;
        }
        async componentDidMount() {
            this.mounted = true;
            const { default: Component } = await importComponent();
            if (this.mounted) {
                this.setState({
                    component: <Component {...this.props} />
                });
            }
        }
        render() {
            const Component = this.state.component ||  
            <div>
                {/* loader */}  
            </div>;
            return Component;
        }
    }
    return AsyncFunc;
}
