import moment from 'moment'

export async function segmentEventTrack (eventDetails) {
    if (eventDetails.event && eventDetails.properties) {
      const properties = { ...eventDetails.properties }
      const traits = getUserTraits()
  
      // console.log(adminUser)
      // if (adminUser && adminUser.length === 0) {
        console.log(
          eventDetails.event,
          {
            ...properties
          },
          { traits }
        )
        window.analytics.track(
          eventDetails.event,
          {
            ...properties
          },
          { traits }
        )
      // }
    }
  }

 export const getUserTraits = () => {
    if (window && window.analytics && window.analytics.user) {
      const userDetails = window.analytics.user().traits()
      const anonymousId = window.analytics.user().anonymousId()
      const userId = window.analytics.user().id()
  
      return {
        anonymousId,
        userId,
        ...userDetails
      }
    }
  
    return {}
  }
  

  export function segmentGuestPageIdentify (user) {
    if (user && user._id) {
      window.analytics.identify(user._id, {
        name: `${user.first_name} ${user.last_name}`,
        email: user.user_email,
        createdAt: user.created_at,
        id: user.user_unique_id,
        title: user.role_id.role_name
      })
    }
  }

  export const segmentTrack=async (user)=> {
    await segmentGuestPageIdentify(user)
    let data = {}
    var isMobile = false
    var today = new Date()
    var time = moment(today).format('LT')
    var date = moment(today).format('L')
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform)
    ) {
      isMobile = true
    } else {
      isMobile = false
    }
    data = {
      event: 'login',
      properties: {
        device: isMobile ? 'Mobile' : 'Desktop',
        date: date,
        time: time,
        app_source:  user.source,
      }
    }
    segmentEventTrack(data)
  }