import axios from "axios";
import config from "../../config";
import { COOKIES_KEY } from "../constUtil";
import { getTokenURL, getJsonCookies, getNonCognitoToken } from "../helpers/utility"

const customHeader = () => ({
    'x-access-token': getJsonCookies(),
    'x-cognito-user' : true,
    'tt_token': getTokenURL(COOKIES_KEY.THUMBTACK_TOKEN)
});

const customNonCognitoHeader = () => ({
    'x-access-token': getNonCognitoToken(),
    'tt_token': getTokenURL(COOKIES_KEY.THUMBTACK_TOKEN)
});

export function api(server) {
    let opts = {
        baseURL: server? config.server:config.api.trim(),
        headers: getJsonCookies() && customHeader(),
    };
    return axios.create(opts);
}
export function adminApi(server) {
    let opts = {
        baseURL: server? config.server:config.adminApi.trim(),
        headers: getJsonCookies() && customHeader(),
    };
    return axios.create(opts);
}
export function nonCognitoDashboardApi(server, token) {
    let headers = null
    if (token) {
        headers = {
            'x-access-token': token,
            'tt_token': getTokenURL(COOKIES_KEY.THUMBTACK_TOKEN)
        }
    } else {
        headers = getNonCognitoToken() && customNonCognitoHeader()
    }
    let opts = {
        baseURL: server? config.dashboardServer: config.dashboardServerApi.trim(),
        headers: headers,
    };
    return axios.create(opts);
}
export function catchHandler(e) {
    let res = e.response && e.response.data ? e.response.data : { message: "Oops! Something went wrong, please try again later" };
    // if (getJsonCookies() && e.response && e.response.status === 401) {
    //     history.push("/login");
    // }
    throw res
}