export const COOKIES_KEY={
    LOGIN_PROVIDER:'loginProvider',
    REFRESH_TOKEN:'refreshToken',
    THUMBTACK_TOKEN: 'tt_token',
    GOOGLE_REFRESH_TOKEN:'googleRefreshToken',
    GOOGLE_ACCESS_TOKEN:'googleAccessToken',
    GOOGLE:'Google',
    FACEBOOK:'Facebook',
    INTUIT:'Intuit',
    GOSITE:'Gosite',
    USER_EMAIL:'user_email',
    INITIAL_EMAIL: 'initial_email'
}

export const GOOGLE_LOGIN_SUCCESS_MSG = 'success'