import React from "react";
import asyncComponent from "../../helpers/async-func";
import { UnRestrictedRoute } from "../../router/access-routes"

const authRoutes = [

    <UnRestrictedRoute
        exact
        key="/login"
        path={"/login"}
        layoutSettings={{ hideHeader: true, hideFooter: true }}
        component={asyncComponent(() => import("./login"))}
    />,
    <UnRestrictedRoute
        exact
        key="/login-with-intuit"
        path={"/login-with-intuit"}
        layoutSettings={{ hideHeader: true, hideFooter: true }}
        component={asyncComponent(() => import("./login-with-intuit"))}
    />, 
    <UnRestrictedRoute
        exact
        key="/signup"
        path={"/signup"}
        layoutSettings={{ hideHeader: true, hideFooter: true }}
        component={asyncComponent(() => import("./signup"))}
    />,
    <UnRestrictedRoute
        exact
        key="/forgot-password"
        path={"/forgot-password"}
        layoutSettings={{ hideHeader: true, hideFooter: true }}
        component={asyncComponent(() => import("./forgot-password"))}
    />,
    <UnRestrictedRoute
        exact
        key="/change-password"
        path={"/change-password"}
        layoutSettings={{ hideHeader: true, hideFooter: true }}
        component={asyncComponent(() => import("./change-password"))}
    />,
// Testing new workflow
    <UnRestrictedRoute
    exact
    key="/register"
    path={"/register"}
    layoutSettings={{ hideHeader: true, hideFooter: true }}
    component={asyncComponent(() => import("./register"))}
/>,
]
export default authRoutes
