import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import authRoutes from "../containers/auth/routes"

const route = ({ history, user }) => {
    return (
        <Router history={history}>
            <Switch>
                {
                    [
                        ...authRoutes,
                    ]
                }
                <Route path="">
                    <Redirect
                        to={{
                            pathname: "/login",
                        }}
                    />
                </Route>
            </Switch>

        </Router>
    );
};
export default route