export const routes = {
    login: '/accounts/login',
    signup: '/accounts/signup',
    hubspotCreateContact: '/users/hubspotCreateContact',
    confirmReg:'/accounts/confirm-registration',
    me: '/users/me',
    generateApiToken: '/users/generate-api-token',
    forgetPassword:'/accounts/forgotPassword',
    resetPassword:'/accounts/confirmPassword',
    firstExperience:'/first-experience/v2',
    resendConfirmationCode: '/accounts/resendConfirmationCode',
    googleAuth: '/auth/google',
    getGMBAuthInfo: '/auth/googlemybusiness',
    getGMBInfo: '/auth/google-information',
    register: '/accounts/register'
}