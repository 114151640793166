import { notification } from "../../components/notification";
import * as service from "./service";
import { deleteAllCookies, setCookiesNonCognito } from "../../helpers/utility";
import { Auth } from 'aws-amplify';

const auth = {
  state: {
    user: {},
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true,
        apiError: null,
      };
    },
    onError(state, data) {
      console.log("onError => ", data);
      if (data && (data.error || data.message)) {
        notification(data.error || data.message, "danger");
      }
      return {
        ...state,
        loading: false,
        apiError: data.error,
      };
    },

    async onMeError(state, data) {
      if (data && data.error) {
        notification(data.error, "danger");
      }
      await Auth.signOut();
      await deleteAllCookies();
      return {
        ...state,
        loading: false,
      };
    },

    onGetMeSuccess(state, data) {
      return {
        ...state,
        loading: false,
        me: data,
      };
    },
    onforgetPwdSuccess(state, data) {
      return {
        ...state,
        loading: false,
      };
    },
    onhubspotCreateContactSuccess(state, data) {
      return {
        ...state,
        loading: false,
      };
    },
    onloginUserSuccess(state, data) {
      return {
        ...state,
        loading: false,
      };
    },
    onAuthSignupSuccess(state, data) {
      setCookiesNonCognito(data.data);
      return {
        ...state,
        loading: false,
        user: data.data,
      };
    },
    onLoginSuccess(state, data) {
      setCookiesNonCognito(data.data);
      return {
        ...state,
        loading: false,
        user: data.data,
      };
    },
  },

  effects: {
    async getMe(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getMe();
        await this.onGetMeSuccess(res);
        return res;
      } catch (e) {
        this.onMeError(e);
      }
    },
    async getNonCognitoMe(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getNonCognitoMe(payload);
        await this.onGetMeSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async hubspotCreateContact(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.hubspotCreateContact(payload);
        await this.onhubspotCreateContactSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async forgetPassword(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.forgetPassword(payload);
        await this.onforgetPwdSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
        return e;
      }
    },
    async resetPassword(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.resetPassword(payload);
        await this.onforgetPwdSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async loginUser(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.loginUser(payload);
        await this.onloginUserSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async resendConfirmationCode(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.resendConfirmationCode(payload);
        // await this.onloginUserSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async googleAuth(payload, rootState) {
      this.onRequest();
      try {
        const res = await service.googleAuth(payload);
        if (res && res.cred) {
          const signupRes = await service.googleAuth({ cred: res.cred });
          await this.onAuthSignupSuccess(signupRes);
          return res;
        } else {
          await this.onLoginSuccess(res);
          return res;
        }
      } catch (e) {
        this.onError(e);
      }
    },
    async googleAuthsignup(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.googleAuth(payload);
        if (res.data.signin === true) {
          await this.onAuthSignupSuccess(res);
        } else {
          res = await service.googleAuth({ cred: res.cred });
          await this.onAuthSignupSuccess(res);
        }
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async getGMBAuthInfo(payload, rootState) {
      try {
        const res = await service.getGMBAuthInfo(payload);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async getGMBInfo(payload, rootState) {
      try {
        const res = await service.getGMBInfo(payload);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async register(payload, rootState) {
      try {
        const res = await service.register(payload);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
  },
};
export default auth;
